export const userStoreKey = 'user';
export const warmTransferStoreKey = 'warmTransfer';
export const topPriorityWorkStoreKey = 'topPriorityWork';
export const openLeadsStoreKey = 'openLeads';
export const metricsStoreKey = 'metrics';

import {
  AdvocatePaidTime,
  OpenLead,
  PrioritizedLeadRecordDetails,
  UserAppointment,
  UserProfile,
  WarmTransferEvent,
  WarmTransferRecord,
  CallLog,
  RepresentativeStatus,
  RepresentativeProfile,
} from 'common.interfaces';
import { QuoteState, quoteStateKey } from './features/quotes/quotes.interface';

export enum RELEASE_PATH {
  BASE = 'releases',
  NOTES_2024_03_11 = 'releases/2024-03-11',
  NOTES_2024_04_03 = 'releases/2024-04-03',
  NOTES_2024_04_22 = 'releases/2024-04-22',
  NOTES_2024_05_14 = 'releases/2024-05-14',
  NOTES_2024_06_04 = 'releases/2024-06-04',
  NOTES_2024_06_13 = 'releases/2024-06-13',
  NOTES_2024_07_22 = 'releases/2024-07-22',
  NOTES_2024_08_21 = 'releases/2024-08-21',
  NOTES_2024_08_28 = 'releases/2024-08-28',
}

export enum PROTEC8_PATH {
  WARM_TRANSFER = 'warm-transfer',
  INCOMING_WARM_TRANSFER = 'incoming-warm-transfer',
  QUOTES = 'quotes',
  DASHBOARD = '',
  RELEASES = RELEASE_PATH.BASE,
  ADVOCATE_SCHEDULE_ADHERENCE = 'advocate-schedule-adherence',
  CALL_SCRIPT_EDIT = 'call-script/edit',
  CALL_SCRIPT_VIEW = 'call-script/view',
  CALL_SCRIPT_ALL = 'call-script',
  UNAUTHORIZED = 'unauthorized',
}

export interface Protec8State {
  [topPriorityWorkStoreKey]: {
    active: boolean;
    prioritizedLeads: PrioritizedLeadRecordDetails[];
    loadingPrioritizedLeads: boolean;
    muted: boolean;
  };
  [quoteStateKey]: QuoteState;
}

export type UserState = {
  userInfo: UserProfile | null | undefined;
  upcomingUserAppointments: UserAppointment[];
  nextWeekScheduledTime: Date[];
  nextWeekScheduledTimeFetched: boolean;
};
export type WarmTransferState = {
  active: boolean;
  muted: boolean;
  loadingHistory: boolean;
  loadingAvailableAdvocates: boolean;
  history: WarmTransferRecord[];
  incoming: WarmTransferEvent[];
  notifiedWte: WarmTransferEvent[];
  displayHistoryPanel: boolean;
};
export type TopPriorityWorkState = Protec8State['topPriorityWork'];
export type MetricsState = {
  callLogsToday: MetricCallLog[];
  leadsTakenThisWeek: LeadTakenThisWeekMetric[];
  timeWorkedMetricsThisWeek: AdvocatePaidTime[];
};
export interface LiveStatusState {
  representativeStatuses: RepresentativeStatus[];
  isLoadingRepresentativesStatuses: boolean;
  representativesProfiles: RepresentativeProfile[];
  isLoadingRepresentativesProfiles: boolean;
}

export interface QuickLink {
  title: string;
  webUrl: string;
  iconUrl: string;
}

export const AVAILABLE_ADVOCATES_HIGH_CHANCE_THRESHOLD = 3;

export type MetricCallLog = Pick<CallLog, 'startTime' | 'externalIdentifier'>;
export interface GroupedMetricCallLogs {
  startTime: Date;
  endTime: Date;
  metrics: MetricCallLog[];
}

export interface GroupedHoursWorkedPerDay {
  dateWorked: Date;
  hoursWorkedForDate: number;
}

export type LeadTakenThisWeekMetric = Pick<OpenLead, 'leadTakenTimestamp' | 'externalIdentifier' | 'contactor'>;

export interface GroupedLeadByHoursTakenToday {
  startTime: Date;
  endTime: Date;
  metrics: LeadTakenThisWeekMetric[];
}

export interface GroupedLeadsByDayTakenThisWeek {
  weekDay: Date;
  metrics: LeadTakenThisWeekMetric[];
}

export type SnoozedTask = {
  id: string /* Matches externalIdentifier from RecommendedTask*/;
  expiresAt: Date /* when to un-snoozed the task */;
};
export enum GOLDEN_TIME_THRESHOLD {
  START_IN_MINUTES = 15,
  END_IN_MINUTES = 60,
}

export enum ADVISOR_NOTIFICATION_TYPE {
  FOLLOW_UP = 'Follow up on leads',
  SCHEDULE_NEXT_WEEK = 'Schedule next week',
}

export enum ADVISOR_NOTIFICATION_LEVEL {
  DANGER = 'Danger',
  WARNING = 'Warning',
  INFO = 'Info',
}

export interface BaseAdvisorNotification {
  snoozedUntil: Date | null;
  level: ADVISOR_NOTIFICATION_LEVEL;
}

export interface SimpleAdvisorNotification extends BaseAdvisorNotification {
  type: ADVISOR_NOTIFICATION_TYPE.SCHEDULE_NEXT_WEEK;
}

export interface RecommendedTaskAdvisorNotification extends BaseAdvisorNotification {
  type: ADVISOR_NOTIFICATION_TYPE.FOLLOW_UP;
  taskIds: string[];
}

export type AdvisorNotification = SimpleAdvisorNotification | RecommendedTaskAdvisorNotification;

export const EXCLUDED_PODS_FROM_RECOMMENDED_TASKS_FILTERS = [
  'Unknown carrier',
  'Unknown Channel Type',
  'Unknown',
  'Product Bounce',
  'TV',
];

export type AdvocateBasePaidTimeInfoWithCallLogs = Pick<
  AdvocatePaidTime,
  'email' | 'startWorkingDay' | 'scheduledSlots' | 'workedTime' | 'userInfo' | 'day' | 'month' | 'year'
> & { callLogs: CallLog[] };

export type WteWithAvailableAdvocates = WarmTransferEvent & { availableAdvocatesCount: number };

export enum CALL_SCRIPT_LEAD_SOURCE {
  DEFAULT = 'Other WT providers',
  CNO = 'Colonial Penn',
}
